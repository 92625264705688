import React, {Component} from 'react'


class MoreTrayBar extends Component {
    state = {  }
    render() { 
        return (
            <div className={`all-icons ${!this.props.isHide ? "open" : ""}`}>
                <i className="fab fa-bluetooth" title="Bluetooth Devices"/>
                <i className="fa fa-wifi" title="Internet Access"/>
            </div>
          );
    }
}
 
export default MoreTrayBar;