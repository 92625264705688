import React, {Component} from 'react'

import { Rnd } from 'react-rnd';

import { connect } from 'react-redux'
import { hideApplication } from '../actions/hideApplication'
import { openApplication } from '../actions/openApplication'
import { putForwardApplication } from '../actions/putForwardApplication'
import { resizeApplication } from '../actions/resizeApplication'
import { dragApplication } from '../actions/dragApplication'

import '../../assets/css/app/core/AppFrame.css'
import '../../assets/css/app/NotepadApp.css'
import '../../assets/css/app/ChromeApp.css'

import chromeIcon from '../../assets/img/chromeIcon.png'
import AppFrame from "./core/AppFrame";


import { ArrowBack, ArrowForward,  Refresh, Star, AccountCircle, MoreVert, Add }  from '@material-ui/icons';

class ChromeApp extends Component {
    constructor(props) {
        super(props);
        this.menu = [{
            label: "File",
            id: "dropdown-file",
            dropdown: [{
                text: "New",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Open",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Save",
                handleClick: this.handleMenuClick,
                withSeparator: true
            }, {
                text: "Page Setup",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Print",
                handleClick: this.handleMenuClick,
                withSeparator: true
            }, {
                text: "Exit",
                handleClick: this.handleHide2,
                withSeparator: false
            }]
        },{
            label: "Edit",
            id: "dropdown-edit",
            dropdown: []
        },{
            label: "Format",
            id: "dropdown-format",
            dropdown: []
        },{
            label: "View",
            id: "dropdown-view",
            dropdown: []
        },{
            label: "Help",
            id: "dropdown-help",
            dropdown: []
        }]
    }


    handleMenuClick = (event) => {
        console.log("click");
    }

    handleFocus = () => {
        this.props.putForwardApplication(this.props.info.id);
    }

    handleHide2 = () => {
        this.props.hideApplication(this.props.info.id);
    }

    handleOpen2 = () => {
        this.props.openApplication(this.props.info.id)
    }

    handleResize = (width, height) => {
        this.props.resizeApplication(width,height, this.props.info.id)
    }

    handleDragStop = (x,y) => {
        this.props.dragApplication(x,y, this.props.info.id)
    }

    render() {
        if(this.props.info !== undefined){
            if(!this.props.info.isHide && !this.props.info.isClose){
        return(
            <Rnd
              size={{ width: this.props.info.width, height: this.props.info.height }}
                position={{ x: this.props.info.x, y: this.props.info.y }}
                onDragStop={(e, d) => {this.handleDragStop(d.x, d.y)}} // this.props.handleDragStop(d.x, d.y)
                minHeight={this.props.info.minHeight}
                minWidth={this.props.info.minWidth}
                onResizeStop={(e, direction, ref, delta, position) => {
                    console.log(position.x, position.y)
                    this.handleResize(ref.style.width,ref.style.height) //...position
                    this.handleDragStop(position.x, position.y)
                }}
               dragHandleClassName="handle-drag"
               style={{zIndex: this.props.info.zindex}}>
                <AppFrame
                    ref={this.ref}
                    title="Chrome"
                    icon={chromeIcon}
                    applicationId="chrome-app"
                    id={this.props.id}
                    onfocus ={this.handleFocus}
                >
                    <div className="row tabs">
                        <div title="New tab" className="col-md-2 tab">Nouvelle onglet</div>
                        <Add fontSize="large"/>
                    </div>

                    <nav>
                        <ArrowBack fontSize="large"/>
                        <ArrowForward fontSize="large"/>
                        <Refresh fontSize="large"/>
                            <input className="search" type="search" placeholder="Effectuez une recherche sur Google ou saisissez une URL" style={{backgroundColor:"white"}}/>
                            <Star fontSize="large"/>
                        <AccountCircle fontSize="large"/>
                        <MoreVert fontSize="large"/>
                    </nav>
                    <iframe title="google.com" src="https://www.google.com/search?igu=1"  height="100%" width="100%" onFocus ={this.handleFocus} style={{overflow: "hidden"}}/>
                </AppFrame>
                </Rnd>
        )
    }
    else{return null}
}
    else{return null}
    }
}


const mapStateToProps = (state, ownProps) => {
    let id = ownProps.id;
    return {
        id : id,
        info: state.applications[id]
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id)),
        resizeApplication: (width,height, id) => dispatch(resizeApplication(width,height, id)),
        dragApplication: (x,y, id) => dispatch(dragApplication(x,y, id))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ChromeApp)