import React from 'react'

import '../../../assets/css/app/core/AppContent.css'

export default function AppContent(props) {
    return(
        <div className="winbody" id={`${props.applicationId}-body`}>
            {props.children}
        </div>
    )
}