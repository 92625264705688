import React, {Component} from 'react'

import { connect } from 'react-redux'
import { hideApplication } from '../../actions/hideApplication'
import { openApplication } from '../../actions/openApplication'
import { putForwardApplication } from '../../actions/putForwardApplication'

class TaskbarIcon extends Component{
    handleClick = () => {
        this.props.openApplication(this.props.info.id)
    }

    

    render() {
        //${!this.props.info.isHide ? "open" : ""}

        if(this.props.info !== undefined){
        return(
            <div className={"icon" + (this.props.info.isHide ? " " : " open")} onClick={this.handleClick}>
                <img src={this.props.imageApp} className="iconAppImage" alt="Logo" />
            </div>
        )}
        else{
            return null;
        }
    }
}


const mapStateToProps = (state, ownProps) => {
    let id = ownProps.id;
    return {
        id : id,
        info: state.applications[id]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id))
    }
  }



export default connect(mapStateToProps, mapDispatchToProps)(TaskbarIcon)