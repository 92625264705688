import React, {Component} from 'react'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { defineApplications } from '../actions/defineApplications'

import firebaseConnector from "../../api/firebase-connector"

import Windows10 from '../../Windows10';

import levels from '../../assets/level.json';

import '../../assets/css/exercice.css';


import mouseHelp from '../../assets/img/mouse/mouseHelp.png'
import mouseHelpInterrogation from '../../assets/img/mouse/mouseHelpInterrogation.png'
import mouseIndication from '../../assets/img/mouse/mouseIndication.png'
import mouseSuccess from '../../assets/img/mouse/mouseSuccess.png'

class Exercice extends Component {
    constructor(props){
        super(props)
        this.nextURL = this.props.location.search ? (this.props.location.search.split("?nextURL=")[1]) : null;
        this.startTime = Date.now();
        this.workshops = levels.workshops.find((workshopsElement)=>workshopsElement.id==this.props.match.params.workshopsId);
        this.exerciceId = parseInt(this.props.match.params.exerciceId)-1;
        this.studentId = this.props.match.params.studentId ? (this.props.match.params.studentId) : null;
        this.adminId = this.props.match.params.adminId ? (this.props.match.params.adminId) : null;
        this.state = {
            nbAides : 0,
            helpActive: false,
            instructionsActive : false,
            leaveActive : false,
            successActive : false,
            mouseState : 0,
            mouseVibrator : false,
            helpOpened : false,
            firstExercice : false,
        }
    }

    componentDidMount(){
        const applications = [
            {name:'TaskBar', zindex : 1, isHide : true, isClose : true, automaticallyHide : false, id : 0},
            {name:'WindowsDesktop', zindex : 1, isHide : false, isClose : false, automaticallyHide : false, id : 1},
            {name:'StartMenu', zindex : 1, isHide : true, isClose : true, automaticallyHide : true, id : 2, powerOff : false},
            {name:'TrayBar', zindex : 1, isHide : true, isClose : true, automaticallyHide : true, id : 3},      
            {name:'Wifi', zindex : 1, isHide : true, isClose : true, automaticallyHide : true, id : 4, connected : false},
            {name:'Notepad', zindex : 1, isHide : true, isClose : false, automaticallyHide : false, id : 5, className : ".notepad1-app", width: 600, height: 480,x: 75,y: 75, minHeight :300, minWidth :300, defaultWidth: 600, defaultHeight: 480, defaultX: 75, defaultY: 75},
            {name:'Chrome', zindex : 1, isHide : true, isClose : false, automaticallyHide : false, id : 6, className : ".chrome-app", width: 700, height: 580,x: 15,y: 15, minHeight :300, minWidth :300, defaultWidth: 700, defaultHeight: 580, defaultX: 15, defaultY: 15},
            {name:'4Cases', zindex : 1, isHide : true, isClose : false, automaticallyHide : true, caseOne : false, caseTwo : false, caseThree: false, caseFour : false, id : 7, type:0},
            {name:'FileExplorer', zindex : 1, isHide : true, isClose : false, automaticallyHide : false, id : 8, className : ".fileExplorer-app",folder:"Home", width: 600, height: 480,x: 75,y: 75, minHeight :300, minWidth :300, defaultWidth: 600, defaultHeight: 480, defaultX: 75, defaultY: 75},
            {name:'TextEditor', zindex : 1, isHide : true, isClose : false, automaticallyHide : false, id : 9, className : ".fileExplorer-app",folder:"Home", width: 600, height: 480,x: 75,y: 75, minHeight :300, minWidth :300, defaultWidth: 600, defaultHeight: 480, defaultX: 75, defaultY: 75},
        ];
        if(this.workshops.exercices[this.exerciceId].initalState != null){
            applications[this.workshops.exercices[this.exerciceId].initalState.id] = this.workshops.exercices[this.exerciceId].initalState.value
        }

        if(this.exerciceId == 0){
            this.setState({
                firstExercice : true,
            })
        }else{
            this.setState({
                instructionsActive : true,
            })
        }

        this.props.defineApplications(applications);
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (eval(this.workshops.exercices[this.exerciceId].validationState) && !this.state.successActive) {
            setTimeout(() => {
                if(!this.state.successActive){
                    this.setState({
                        successActive : true,
                    })
                    if(this.adminId && this.studentId){
                        firebaseConnector.firestore().collection("Users_Data").doc("" + this.adminId).collection("Usagers").doc("" + this.studentId).get().then((doc) => {
                            var exercices = doc.data().exercicesResults ? doc.data().exercicesResults : [];
                            exercices.push({
                                date : new Date(Date.now()),
                                idAtelier : parseInt(this.workshops.id),
                                idExercice : parseInt(this.exerciceId + 1),
                                nbAides : parseInt(this.state.nbAides)
                            });
                            firebaseConnector.firestore().collection("Users_Data").doc("" + this.adminId).collection("Usagers").doc("" + this.studentId).set({
                                exercicesResults : exercices
                            }, {merge : true})
                        })
                    }
                }
            },1000);
        }
        if(!this.state.firstExercice && !this.state.instructionsActive && !this.state.helpActive && !this.state.successActive && !this.state.helpOpened){
            setTimeout(() => {
                if(!this.state.helpOpened){
                    this.setState({
                        mouseVibrator : true,
                    })
                }
            },this.workshops.exercices[this.exerciceId].mouseTimeApparition * 1000);
        }
    }
    handleIntroduction= () => {
        this.setState({
            instructionsActive : true,
            firstExercice : false,
        })
    }

    handleHelp = () => {
        if(this.state.helpActive){
            this.setState({
                nbAides : this.state.nbAides + 1
            })
        }
        if(this.state.instructionsActive){   
            this.setState({
                instructionsActive : false
            })
        }
        else{
            this.setState({
                mouseVibrator : false,
                helpActive : !this.state.helpActive,
                leaveActive : false,
                helpOpened : true,
            })
        }
    }
    handleInstruction= () => {
        this.setState({
            instructionsActive : false,
        })
    }
    handleLeave= () => {
        this.setState({
            leaveActive : !this.state.leaveActive,
            instructionsActive : false,
            helpActive : false
        })
    }
    mouseImage= () => {
        if(this.state.instructionsActive){
            return mouseIndication 
        }
        else if(this.state.helpActive){
            return mouseIndication
        }
        else if(this.state.successActive){
            return mouseSuccess
        }
        else{
            return mouseHelp
        }
    }
    mouseImage2= () => {
        if(!this.state.instructionsActive && !this.state.helpActive && !this.state.successActive){
            return <img src={mouseHelpInterrogation} className={this.state.mouseVibrator ? 'help-button help-button-shake' : 'help-button'} onClick={this.handleHelp}/>
        }
    }
    mouseImage2Style = () =>{
        if(!this.state.instructionsActive && !this.state.helpActive && !this.state.successActive){
            if(this.state.mouseVibrator){
                return 'help-button help-button-shake' ;
            }
            else{
                return 'help-button';
            }
        }
        else{
            return 'displayNone';
        }
    }
    nextLevel = () => {
        if(this.nextURL != null){
            window.location.href =  this.nextURL + "?nbAides=" + this.state.nbAides + "?temps=" + ( Date.now()- this.startTime);
        }else{
            if(this.workshops.exercices[this.exerciceId +1] != null){
                if(this.adminId && this.studentId){
                    this.props.history.push(`/Workshop/${this.workshops.id}/Exercice/${(this.exerciceId+2)}/${(this.adminId)}/${(this.studentId)}`); 
                }
                else{
                    this.props.history.push(`/Workshop/${this.workshops.id}/Exercice/${(this.exerciceId+2)}`); 
                }
                this.props.history.go();
            }
            else{
                this.props.history.push(`/Goodbye`); 
                this.props.history.go();
            }
        }
    }

    render() {
        return (
            <div>



                {/* ---------------------------- Progress bar ---------------------------- */}
                <div className="row" style={{textAlign: 'center', marginTop : '3vh', marginBottom: '1vh'}}>
                    <h1 style={{fontSize: "4vh",textAlign: 'center', margin: '1vh'}}>Objectif : {this.workshops.exercices[this.exerciceId].description}</h1>
                </div>
                <div className="row progress_bar">
                {
                    this.workshops.exercices.map((exercice, index) => (
                        <div key={index} className={`col-md-6 ${(parseInt(exercice.id)-1) < this.exerciceId+1 ? "progress_validate" : "progress_non_validate"}`}  title={"Exercice " + exercice.id} style={{width: (100/this.workshops.exercices.length)+"%"}} 
                            onClick={()=>{
                                if(this.adminId && this.studentId){
                                    this.props.history.push(`/Workshop/${this.workshops.id}/Exercice/${(exercice.id)}/${(this.adminId)}/${(this.studentId)}`); 
                                    this.props.history.go();
                                }
                                else if (!this.nextURL){
                                    this.props.history.push(`/Workshop/${this.workshops.id}/Exercice/${(exercice.id)}`); 
                                    this.props.history.go();
                                }
                            }}/>
                    ))
                }
                </div>



                {/* ---------------------------- Computer ---------------------------- */}

                <div className="desktopMockup">
                    <div className="insideMockup">
                    <Windows10/>
                    </div>
                </div>



                {/* ---------------------------- Buttons ---------------------------- */}

                {this.mouseImage2()}
                <img src={this.mouseImage()} className='help-button' onClick={this.handleHelp}/>
                <button className="leave-button" onClick={this.handleLeave}>
                    Quitter
                </button>



                {/* ---------------------------- Introduction ---------------------------- */}

                <div className={this.state.firstExercice ? 'help-overlay help-active speech-bubble-ds' : 'help-overlay'} id="help-overlay">
                    <div className="vertical-horizontal-center">
                        <h2 className="exercice-help">
                            Bonjour, Je suis Math ! <br/> Bienvenue sur AtelierNum. Je suis là pour vous accompagner. <br/> <br/> Alors n'hésitez pas à cliquer sur moi si vous avez besoin d'aide.
                        </h2>
                        <button className="next" onClick={this.handleIntroduction}>
                            Commencer
                        </button>
                    </div>
                </div>



                {/* ---------------------------- Help ---------------------------- */}

                <div className={this.state.helpActive ? 'help-overlay help-active speech-bubble-ds' : 'help-overlay'} id="help-overlay">
                    <div className="vertical-horizontal-center">
                        <h2 className="exercice-help">
                            {this.workshops.exercices[this.exerciceId].description}
                        </h2>
                        <h2>
                            Indice : {this.workshops.exercices[this.exerciceId].mouseIndication.text}
                        </h2>
                        <div className="row">
                            <img src={require('../../assets/img/mouse/' + this.workshops.exercices[this.exerciceId].mouseIndication.image)} className="image-help" />
                        </div>

                        <button className="next" onClick={this.handleHelp}>
                            Merci
                        </button>
                    </div>
                </div>




                {/* ---------------------------- Instruction ---------------------------- */}

                <div className={this.state.instructionsActive ? 'help-overlay help-active speech-bubble-ds' : 'help-overlay'} id="help-overlay">
                    <div className="vertical-horizontal-center">
                        <h2 className="exercice" style={{marginBottom : "5%"}}>
                            {this.workshops.exercices[this.exerciceId].description}
                        </h2>
                        <button className="next" onClick={this.handleInstruction}>
                            J'essaye
                        </button>
                    </div>
                </div>
                <div className={this.state.successActive ? 'help-overlay help-active speech-bubble-ds' : 'help-overlay'} id="help-overlay">
                    <div className="vertical-horizontal-center">
                    <h2 className="exercice">
                        Félicitations, vous avez réussi !
                    </h2>
                    <button className="next" onClick={() => this.nextLevel()}>
                        Passer à l'exercice suivant
                    </button>
                    </div>
                </div>
                



                {/* ---------------------------- Leave ---------------------------- */}

                <div className={this.state.leaveActive ? 'leave-overlay leave-active speech-bubble-ds' : 'leave-overlay'} id="leave-overlay">
                
                    <div className="vertical-horizontal-center">
                    <h2 className="exercice">
                        Etes-vous sur de vouloir quitter ? 
                    </h2>

                    <button className="next" onClick={()=>{
                        if(this.nextURL){
                            window.location.href = this.nextURL+"?leave=yes"
                        }else{
                            this.props.history.push(`/`)}
                        }
                        }>
                         Oui
                    </button>
                    <button className="next" onClick={this.handleLeave}>
                         Non
                    </button>
                    </div>
                </div>


                
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        applications: state.applications
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        defineApplications: (applications) => dispatch(defineApplications(applications))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Exercice));