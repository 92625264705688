import React, {Component} from 'react'
import TaskbarTimer from "./TaskbarTimer"
import MoreTrayBar from "./MoreTrayBar"
import  Wifi  from "./Wifi"

import { connect } from 'react-redux'
import { hideApplication } from '../../actions/hideApplication'
import { openApplication } from '../../actions/openApplication'
import { putForwardApplication } from '../../actions/putForwardApplication'


class Traybar extends Component{
    state={
        trayMenuToggle: false
    }

    handleTrayMenuClick = (e) => {
        if(this.props.application.isHide === true && e.target.className !=="" && e.target.className !=="col-xs-10"){
            this.props.openApplication(this.props.id);
        }
        else if(e.target.className ==="fa fa-wifi"){
            this.props.openApplication(4);
        }
        else if(e.target.className ==="" && e.target.className ==="col-xs-10"){

        }
        else{
            this.props.hideApplication(this.props.id);
        }
        console.log("this.props.application.isHide")
        console.log(this.props.application.isHide)
    }

    render() {
        return (
            <div className="tray">
                <div className="ico more-icons" style={{marginRight: "1rem", fontSize: "1em!important"}}
                     onClick={this.handleTrayMenuClick}>
                     <MoreTrayBar isHide = {this.props.application.isHide}/>
                     <Wifi id={4}/>
                    <div className="fal fa-chevron-up" title="Show hidden icons"/>
                </div>
                <div className="ico main-ico" title="Speakers: 80%">
                    <i className="fal fa-volume-up"/>
                </div>
                <div className="ico main-ico" title="100% remaining">
                    <i className="fal fa-battery-three-quarters"/>
                </div>

                <TaskbarTimer/>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        id : 3,
        application: state.applications[3]
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id))
    }
  }



export default connect(mapStateToProps, mapDispatchToProps)(Traybar)