import React, {Component} from 'react'


class TaskbarTimer extends Component{
    state={
        date: "25/09/2020",
        clock: "11:13"
    }

    componentDidMount() {
        this.setState({
            date: new Intl.DateTimeFormat('fr-FR').format(new Date()).toString()
        });

        setInterval(this.formatTime, 1000);
    }

    formatTime = () => {
        const dt = new Date();
        const hour = dt.getHours();
        const minute = dt.getMinutes();

        this.setState({
            clock: `${hour}:${minute}`
        });
    }

    render() {
        return(
            <div className="ico time" style={{marginRight: "2rem"}}>
                <div className="clock">{this.state.clock}</div>
                <div className="date">{this.state.date}</div>
            </div>
        )
    }
}

export default TaskbarTimer