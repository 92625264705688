import React, {Component} from 'react'

import { connect } from 'react-redux'

import { powerOff } from '../../actions/powerOff'

import '../../../assets/css/desktop/appBar/PowerMenu.css'


class PowerMenu extends Component {

    handleClickPowerOff = () => {
        this.props.powerOff(this.props.id);
    }

    render() { 
        if(this.props.isHide === false){
            return ( 
                <div className="col-xs-5 programsPowerMenu" style={{position: "absolute", top : "250px", backgroundColor:"#292929"}}>
                    <div className="item" >
                        <i className= "far fa-moon" />
                        <span>Mettre en veille</span>
                    </div>
                    <div className="item" onClick={this.handleClickPowerOff}>
                        <i className= "fal fa-power-off" />
                        <span>Arreter</span>
                    </div>
                    <div className="item" >
                        <i className= "fal fa-undo-alt" />
                        <span>Redemarrer</span>
                    </div>
                </div>
         );
        }
        else{
            return ( 
                null
         );
        }
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        id : ownProps.id,
        applications: state.applications
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        powerOff: (id) => dispatch(powerOff(id))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PowerMenu)