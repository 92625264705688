import React, {Component} from 'react'

import '../../../assets/css/app/core/AppMenubar.css'

export default class AppMenubar extends Component {
    state={
        dropdownOpened: ""
    }

    handleClickDropdown = (value) => (event) => {
        if(this.state.dropdownOpened === value){  
            this.setState({
                dropdownOpened: ""
            })
        }
        else{
            this.setState({
                dropdownOpened: value
            })
        }
    }

    handleMouseLeaveDropdown = (event) => {
        this.setState({
            dropdownOpened: ""
        })
    }

    render() {
        return (
            <>
                <div className="menubar">
                    {this.props.menu.map((value, index) => (
                        <button
                            className="menubarItem"
                            key={index}
                            onClick={this.handleClickDropdown(value.id)}
                            id={value.id}
                        >
                            {value.label}
                        </button>
                    ))}
                </div>
                {this.state.dropdownOpened !== "" && this.props.menu.find(value => value.id === this.state.dropdownOpened).dropdown.length > 0 ? (
                    <div id={`${this.state.dropdownOpened}`} className="dropdown">
                        {this.props.menu.find(value => value.id === this.state.dropdownOpened).dropdown.map((subItem, indexSubItem) => {
                            return (
                                <div key={indexSubItem}>
                                    <button onClick={subItem.handleClick}>{subItem.text}</button>
                                    {subItem.withSeparator ? (<hr className="dropdown-separator"/>) : ""}
                                </div>
                            )
                        })}
                    </div>
                ) : ""}

            </>
        )
    }
}