import React, {Component} from 'react';

import { connect } from 'react-redux'
import { validateCase } from '../actions/validateCase'

import '../../assets/css/app/core/AppFrame.css'
import '../../assets/css/app/NotepadApp.css'


class CasesApp extends Component {
    constructor() {
        super();
        this.state = {
            width: 600,
            height: 480,
            x: 75,
            y: 75,
            caseBackground : ["red","red","red","red",],
          };
    }

    handlehover = (caseId) => () => {
        console.log("Hover");
        console.log(caseId);
        if(this.props.info.type ==0){
            var newCaseBackground = this.state.caseBackground
            newCaseBackground[caseId-1] = "green"
            this.setState({
                caseBackground : newCaseBackground,
            })
            this.props.validateCase(caseId);
        }
    }
    handleClick = (caseId) => () => {
        console.log("Hover");
        console.log(caseId);
        if(this.props.info.type ==1){
            var newCaseBackground = this.state.caseBackground
            newCaseBackground[caseId-1] = "green"
            this.setState({
                caseBackground : newCaseBackground,
            })
            this.props.validateCase(caseId);
        }
    }

    render() {
        if(this.props.info !== undefined){
            if(!this.props.info.isHide && !this.props.info.isClose){
        return(
            <div style={{marginLeft : "10%", width : "80%", height : "80%", opacity: 0.73}}>
                <div className="row justify-content-between" style={{ display: "flex", justifyContent: "space-between", height : "50%"}}>
                    <div onMouseOver={this.handlehover(1)} onClick={this.handleClick(1)} className="col-md-3" style={{backgroundColor : this.state.caseBackground[0], marginTop:"30px", height : "80%"}}>
                    </div>
                    <div onMouseOver={this.handlehover(2)} onClick={this.handleClick(2)}  className="col-md-3" style={{backgroundColor : this.state.caseBackground[1], marginTop:"30px", height : "80%"}}>
                    </div>
                </div>
                <div className="row justify-content-between" style={{ display: "flex", justifyContent: "space-between", height : "50%"}}>
                    <div onMouseOver={this.handlehover(3)} onClick={this.handleClick(3)}  className="col-md-3" style={{backgroundColor : this.state.caseBackground[2], marginTop:"30px", height : "80%"}}>
                    </div>
                    <div onMouseOver={this.handlehover(4)} onClick={this.handleClick(4)}  className="col-md-3" style={{backgroundColor : this.state.caseBackground[3], marginTop:"30px", height : "80%"}}>
                    </div>
                </div>
            </div>
        )
    }else{
        return(null)
    }
    }
}
}

const mapStateToProps = (state, ownProps) => {
    let id = ownProps.id;
    console.log("Cases App : ")
    console.log(state.applications[id])
    return {
        id : id,
        info: state.applications[id]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        validateCase: (id) => dispatch(validateCase(id)),
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(CasesApp)