
import React, {Component} from 'react'

import { connect } from 'react-redux'
import { hideApplication } from '../../actions/hideApplication'
import { openApplication } from '../../actions/openApplication'
import { putForwardApplication } from '../../actions/putForwardApplication'

class StartMenuList extends Component{
    handleClick = () => {
        if(this.props.id !== undefined){
            this.props.openApplication(this.props.id)
        }
    }

    

    render() {
        
        if(this.props.info !== undefined){
            if(this.props.imageApp !== undefined){
                return(
                    <div className="item" onClick={this.handleClick}>
                        <img src={this.props.imageApp} className="iconAppImage" alt="Logo" />
                        <span>{this.props.text}</span>
                    </div>
                )
            }else{   
                return(
                    <div className="item" onClick={this.handleClick}>
                        <i className={`${this.props.icon} ${this.props.iconSize}`} />
                        <span>{this.props.text}</span>
                    </div>
                )
            }
        }
        else{
            return null;
        }
    }
}


const mapStateToProps = (state, ownProps) => {
    let id = ownProps.id;
    return {
        id : id,
        info: state.applications
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id))
    }
  }



export default connect(mapStateToProps, mapDispatchToProps)(StartMenuList)