import React, {Component} from 'react'

import { connect } from 'react-redux'
import { hideApplication } from '../../actions/hideApplication'
import { openApplication } from '../../actions/openApplication'
import { putForwardApplication } from '../../actions/putForwardApplication'
import { resizeApplication } from '../../actions/resizeApplication'
import { fullResizeApplication } from '../../actions/fullResizeApplication'
import { dragApplication } from '../../actions/dragApplication'

import AppContent from "./AppContent";

import '../../../assets/css/app/core/AppFrame.css'

class AppFrame extends Component{

    handleClickHide = (event) => {
        this.props.hideApplication(this.props.info.id);
    }

    handleClickClose = (event) => {
        this.props.hideApplication(this.props.info.id);
    }

    handleFullResize =() => {
        if(this.props.info.width !=="100%"){
            this.props.fullResizeApplication(this.props.info.id)
        }
        else{
            this.props.resizeApplication(this.props.info.defaultWidth, this.props.info.defaultHeight, this.props.info.id);
            this.props.dragApplication(this.props.info.defaultX, this.props.info.defaultY, this.props.info.id)
        }
    }

    render() {
        
    let iconSize;
    if (this.props.info. width != "100%") {
        iconSize = <div style={{fontSize: "23px"}}>&#9633;</div>;
      } else {
        iconSize = <div style={{fontSize: "23px"}}>&#10064;</div>;
      }
    if(this.props.info !== undefined){
        if(!this.props.info.isHide && !this.props.info.isClose){
            return(
                
                 
                    <div className="window" id={this.props.applicationId} tabIndex="0" onFocus={this.props.onfocus} >
                        <div className="titleFrame handle-drag" onDoubleClick={this.handleFullResize}>
                            <div className="titleContent">
                                <div className="iconContainer">
                                    <img
                                        className="icon"
                                        src={this.props.icon}
                                        alt={`icon-${this.props.applicationId.applicationId}`}
                                        width="16px"
                                        height="16px"
                                    />
                                </div>
                                <div className="titleText">{this.props.title}</div>
                            </div>
                            <div  id="npClose">
                                <button className="titleBarBtn closeBtn" onClick={this.handleClickClose}>
                                    <div>
                                        &times;
                                    </div>
                                </button>
                            </div>
                            <div  id="npClose">
                                <button className="titleBarBtn " onClick={this.handleFullResize}>
                                    {iconSize}
                                </button>
                            </div>
                            <div  id="npClose">
                                <button className="titleBarBtn" onClick={this.handleClickHide}>
                                    <div>
                                        &minus;
                                    </div>
                                </button>
                            </div>
                        </div>
                        <AppContent applicationId={this.props.applicationId}>
                            {this.props.children}
                        </AppContent>
                    </div>
            )
            }
            else{
                return null
            }
        }
        else{
            return null
        }
    }
}


const mapStateToProps = (state, ownProps) => {
    const id = ownProps.id;
    return {
        info: state.applications[id]
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id)),
        fullResizeApplication : (id) => dispatch(fullResizeApplication(id)),
        resizeApplication: (width,height, id) => dispatch(resizeApplication(width,height, id)),
        dragApplication: (x,y, id) => dispatch(dragApplication(x,y, id))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AppFrame)