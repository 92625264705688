import React from 'react'


function StartMenuTile(props) {
    return(
        <div className={`tile ${props.size}`}>
            <i className={`${props.icon} ${props.iconSize}`} style={{marginTop: "2rem"}}/>
            <span>{props.text}</span>
        </div>
    )
}

export default StartMenuTile