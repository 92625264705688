import React, {Component} from 'react'

import { connect } from 'react-redux'
import { hideApplication } from '../actions/hideApplication'
import { openApplication } from '../actions/openApplication'
import { putForwardApplication } from '../actions/putForwardApplication'

import '../../assets/css/desktop/WindowsTaskbar.css'
import WindowsStartMenu from "./WindowsStartMenu";
import TaskbarIcon from "./appBar/TaskbarIcon";
import Traybar from "./appBar/Traybar"

import notepadIcon from '../../assets/img/notepadIcon.png'
import chromeIcon from '../../assets/img/chromeIcon.png'

class WindowsTaskbar extends Component{

    handleWinClick = event => {
        if(this.props.applications[2].isHide === true){
            this.props.openApplication(this.props.applications[2].id);
        }
        else{
            this.props.hideApplication(this.props.applications[2].id);
        }
    }


    handleTaskClick = (e) => {
        console.log("Clique sur la taskbar classe :", e.target.className)
        if(e.target.className === "icon" || e.target.className === "fab fa-windows" ){
            this.handleWinClick()
        }
        else if(e.target.className === "fa fa-chevron-up"  || e.target.className === "ico more-icons"  || e.target.className === ""){

        }
        else if (e.target.className === "programs" || e.target.className === "tray"){
            this.props.putForwardApplication(this.props.id);
        }
    }

    render() {
        return(
            <div className="taskbar" onClick={this.handleTaskClick}>
                <div className="programs">
                    <div className="icon" onClick={this.handleWinClick}>
                        <i className="fab fa-windows"/>
                    </div>
                    <TaskbarIcon softwareId="cortana" icon="far fa-circle"/>
                    <TaskbarIcon softwareId="Bloc-notes" imageApp={notepadIcon} id={5}/>
                    <TaskbarIcon softwareId="Google Chrome" imageApp={chromeIcon} id={6}/>
                </div>
                <Traybar/>
                <WindowsStartMenu id={2}/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id : ownProps.id,
        applications: state.applications
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id))
    }
  }



export default connect(mapStateToProps, mapDispatchToProps)(WindowsTaskbar)