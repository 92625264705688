import React, {Component} from 'react'

import { Rnd } from 'react-rnd';

import { connect } from 'react-redux'
import { hideApplication } from '../actions/hideApplication'
import { openApplication } from '../actions/openApplication'
import { putForwardApplication } from '../actions/putForwardApplication'
import { resizeApplication } from '../actions/resizeApplication'
import { dragApplication } from '../actions/dragApplication'
import { changePathFileExplorer } from '../actions/changePathFileExplorer'

import { ArrowBack, ArrowForward, ExpandMore, ArrowUpward }  from '@material-ui/icons';



import '../../assets/css/app/core/AppFrame.css'
import '../../assets/css/app/NotepadApp.css'
import '../../assets/css/app/FileExplorerApp.css'

import fileExplorerIcon from '../../assets/img/FileExplorer.png'
import quickAccessIcon from '../../assets/img/fileExplorer/Quick-Access-Icon.png'
import cePCIcon from '../../assets/img/fileExplorer/Ce-PC.png'
import desktopIcon from '../../assets/img/fileExplorer/Desktop.png'
import documentsIcon from '../../assets/img/fileExplorer/Documents.png'
import downloadIcon from '../../assets/img/fileExplorer/Download.png'
import picturesIcon from '../../assets/img/fileExplorer/Pictures.png'
import musicsIcon from '../../assets/img/fileExplorer/Music.png'
import objectsIcon from '../../assets/img/fileExplorer/3dObjects.png'
import videosIcon from '../../assets/img/fileExplorer/Videos.png'



import AppFrame from "./core/AppFrame";
import AppMenubar from "./core/AppMenubar";
import FileExplorerDocuments from "./FileExplorer/FileExplorerDocuments";

class FileExplorer extends Component {
    constructor() {
        super();
        this.state = {
            width: 600,
            height: 480,
            x: 75,
            y: 75
          };
        this.menu = [{
            label: "Fichier",
            id: "dropdown-file",
            dropdown: [{
                text: "Nouveau",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Ouvrir...",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Enregistrer",
                handleClick: this.handleMenuClick,
                withSeparator: true
            }, {
                text: "Mise en page...",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Imprimer",
                handleClick: this.handleMenuClick,
                withSeparator: true
            }, {
                text: "Quitter",
                handleClick: this.handleHide2,
                withSeparator: false
            }]
        },{
            label: "Edition",
            id: "dropdown-edit",
            dropdown: []
        },{
            label: "Format",
            id: "dropdown-format",
            dropdown: []
        },{
            label: "Affichage",
            id: "dropdown-view",
            dropdown: []
        },{
            label: "Aide",
            id: "dropdown-help",
            dropdown: []
        }]
    }
    handleMenuClick = (event) => {
        console.log("click");
    }

    handleFocus = () => {
        console.log("Notepad focus")
        this.props.putForwardApplication(this.props.info.id);
    }

    handleHide2 = () => {
        this.props.hideApplication(this.props.info.id);
    }



    handleResize = (width, height) => {
        this.props.resizeApplication(width,height, this.props.info.id)
    }

    handleDragStop = (x,y) => {
        this.props.dragApplication(x,y, this.props.info.id)
    }
    handleChangePath = (newPath) => {
        this.props.changePathFileExplorer(this.props.info.id, newPath)
    }

    render() {
        if(this.props.info !== undefined){
            if(!this.props.info.isHide && !this.props.info.isClose){
        return(
            <Rnd
              size={{ width: this.props.info.width, height: this.props.info.height }}
                position={{ x: this.props.info.x, y: this.props.info.y }}
                onDragStop={(e, d) => {this.handleDragStop(d.x, d.y)}} // this.props.handleDragStop(d.x, d.y)
                minHeight={this.props.info.minHeight}
                minWidth={this.props.info.minWidth}
                onResizeStop={(e, direction, ref, delta, position) => {
                    console.log(position.x, position.y)
                    this.handleResize(ref.style.width,ref.style.height) //...position
                    this.handleDragStop(position.x, position.y)
                }}
                style={{zIndex: this.props.info.zindex}}
               dragHandleClassName="handle-drag">
            <AppFrame
                ref={this.ref}
                title="Explorateur de fichiers"
                icon={fileExplorerIcon}
                applicationId="notepad-app"
                id={this.props.id}
                onfocus ={this.handleFocus}
            >
                <AppMenubar menu={this.menu} />

                    <nav style={{backgroundColor : "white"}}>
                        <ArrowBack fontSize="large"/>
                        <ArrowForward fontSize="large"/>
                        <ArrowUpward fontSize="large"/>
                        <div  style={{width: "100%", }}>
                            <input className="search-file-explorer" type="search" defaultValue="Ce PC > Mes documents" style={{backgroundColor:"white", width: "65%",border: "0.2rem solid #E0E0E0",}}/>
                            <input className="search-file-explorer" type="search" placeholder="Rechercher" style={{backgroundColor:"white", width: "25%",border: "0.2rem solid #E0E0E0",}}/>
                        </div>
                    </nav>
                <div className="col-md-4" style={{backgroundColor : "white", borderRight: "0.2rem solid #E0E0E0",  height : "100%"}}>
                <h5><img alt="quickAccessIcon" src={quickAccessIcon} width="16px" height="16px" /> Accès rapide <ExpandMore fontSize="small"/></h5>
                <ul style={{listStyleType:"none"}}>
                    <li><img alt="desktopIcon" src={desktopIcon} width="16px" height="16px" />Bureau</li>
                    <li><img alt="downloadIcon" src={downloadIcon} width="16px" height="16px"/>Téléchargements</li>
                    <li><img alt="documentsIcon" src={documentsIcon} width="16px" height="16px"/>Documents</li>
                    <li><img alt="picturesIcon" src={picturesIcon} width="16px" height="16px"/>Images</li>
                </ul>
                <h5><img src={cePCIcon} width="16px" height="16px" /> Ce PC <ExpandMore fontSize="small"/></h5>
                <ul style={{listStyleType:"none"}}>
                    <li><img alt="desktopIcon" src={desktopIcon} width="16px" height="16px"/>Bureau</li>
                    <li><img alt="documentsIcon" src={documentsIcon} width="16px" height="16px"/>Documents</li>
                    <li><img alt="picturesIcon" src={picturesIcon} width="16px" height="16px"/>Images</li>
                    <li><img alt="musicsIcon" src={musicsIcon} width="16px" height="16px"/>Musiques</li>
                    <li><img alt="objectsIcon" src={objectsIcon} width="16px" height="16px"/>Objets 3D</li>
                    <li><img alt="downloadIcon" src={downloadIcon} width="16px" height="16px"/>Téléchargements</li>
                    <li><img alt="videosIcon" src={videosIcon} width="16px" height="16px"/>Vidéos</li>
                </ul>
                </div>
                <div className="col-md-8" style={{backgroundColor : "white", height : "100%"}}>
                    <FileExplorerDocuments  id={8}></FileExplorerDocuments>
                </div>

            </AppFrame>
                </Rnd>
        )
    }else{
        return null;
    }
    }
}
}

const mapStateToProps = (state, ownProps) => {
    let id = ownProps.id;
    return {
        id : id,
        info: state.applications[id]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id)),
        resizeApplication: (width,height, id) => dispatch(resizeApplication(width,height, id)),
        dragApplication: (x,y, id) => dispatch(dragApplication(x,y, id)),
        changePathFileExplorer: (id, newPath) => dispatch(changePathFileExplorer(id, newPath))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(FileExplorer)