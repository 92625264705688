import React, {Component} from 'react'

import { Rnd } from 'react-rnd';

import { connect } from 'react-redux'
import { hideApplication } from '../actions/hideApplication'
import { openApplication } from '../actions/openApplication'
import { putForwardApplication } from '../actions/putForwardApplication'
import { resizeApplication } from '../actions/resizeApplication'
import { dragApplication } from '../actions/dragApplication'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import '../../assets/css/app/core/AppFrame.css'
import '../../assets/css/app/NotepadApp.css'

import textEditorIcon from '../../assets/img/textEditor.png'
import AppFrame from "./core/AppFrame";
import AppMenubar from "./core/AppMenubar";

class TextEditor extends Component {
    constructor() {
        super();
        this.state = {
            width: 600,
            height: 480,
            x: 75,
            y: 75,
            editorText:'',
          };
          this.modules = {
			toolbar: [
		      [{ 'font': [] }],
		      [{ 'size': ['small', false, 'large', 'huge'] }],
		      ['bold', 'italic', 'underline'],
		      [{'list': 'ordered'}, {'list': 'bullet'}],
		      [{ 'align': [] }],
		      [{ 'color': [] }, { 'background': [] }],
		      ['clean']
		    ]
		};

		this.formats = [
		    'font',
		    'size',
		    'bold', 'italic', 'underline',
		    'list', 'bullet',
		    'align',
		    'color', 'background'
	  	];
        this.menu = [{
            label: "Fichier",
            id: "dropdown-file",
            dropdown: [{
                text: "Nouveau",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Ouvrir...",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Enregistrer",
                handleClick: this.handleMenuClick,
                withSeparator: true
            }, {
                text: "Mise en page...",
                handleClick: this.handleMenuClick,
                withSeparator: false
            }, {
                text: "Imprimer",
                handleClick: this.handleMenuClick,
                withSeparator: true
            }, {
                text: "Quitter",
                handleClick: this.handleHide2,
                withSeparator: false
            }]
        },{
            label: "Edition",
            id: "dropdown-edit",
            dropdown: []
        },{
            label: "Format",
            id: "dropdown-format",
            dropdown: []
        },{
            label: "Affichage",
            id: "dropdown-view",
            dropdown: []
        },{
            label: "Aide",
            id: "dropdown-help",
            dropdown: []
        }]
    }

    handleChangeEditor = text => {
        this.setState({ editorText: text });
        console.log("Editor : " + this.state.editorText)
    };


    

    handleMenuClick = (event) => {
        console.log("click");
    }

    handleFocus = () => {
        console.log("Notepad focus")
        this.props.putForwardApplication(this.props.info.id);
    }

    handleHide2 = () => {
        this.props.hideApplication(this.props.info.id);
    }



    handleResize = (width, height) => {
        this.props.resizeApplication(width,height, this.props.info.id)
    }

    handleDragStop = (x,y) => {
        this.props.dragApplication(x,y, this.props.info.id)
    }

    render() {
        if(this.props.info !== undefined){
            if(!this.props.info.isHide && !this.props.info.isClose){
        return(
            <Rnd
              size={{ width: this.props.info.width, height: this.props.info.height }}
                position={{ x: this.props.info.x, y: this.props.info.y }}
                onDragStop={(e, d) => {this.handleDragStop(d.x, d.y)}} // this.props.handleDragStop(d.x, d.y)
                minHeight={this.props.info.minHeight}
                minWidth={this.props.info.minWidth}
                onResizeStop={(e, direction, ref, delta, position) => {
                    console.log(position.x, position.y)
                    this.handleResize(ref.style.width,ref.style.height) //...position
                    this.handleDragStop(position.x, position.y)
                }}
                style={{zIndex: this.props.info.zindex}}
               dragHandleClassName="handle-drag">
            <AppFrame
                ref={this.ref}
                title="Sans titre - Editeur de texte"
                icon={textEditorIcon}
                applicationId="notepad-app"
                id={this.props.id}
                onfocus ={this.handleFocus}

            >
                <AppMenubar menu={this.menu}/>
                <ReactQuill 
                theme="snow" 
                value={this.state.editorText} 
                onChange={this.handleChangeEditor}
                modules={this.modules}
				formats={this.formats}  
                style={{height:"100%"}}
                />
            </AppFrame>
                </Rnd>
        )
    }else{
        return null;
    }
    }
}
}

const mapStateToProps = (state, ownProps) => {
    let id = ownProps.id;
    return {
        id : id,
        info: state.applications[id]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id)),
        resizeApplication: (width,height, id) => dispatch(resizeApplication(width,height, id)),
        dragApplication: (x,y, id) => dispatch(dragApplication(x,y, id))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(TextEditor)