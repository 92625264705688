import React, { Component } from "react";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { defineApplications } from "../actions/defineApplications";

import firebaseConnector from "../../api/firebase-connector";

class Home extends Component {
    constructor(props) {
        super(props);

        this.studentId = this.props.match.params.studentId
            ? this.props.match.params.studentId
            : null;
        this.adminId = this.props.match.params.adminId
            ? this.props.match.params.adminId
            : null;

        firebaseConnector
            .firestore()
            .collection("Users_Data")
            .doc("" + this.adminId)
            .collection("Usagers")
            .doc("" + this.studentId)
            .get()
            .then((doc) => {
                var usager = doc.data();
                var exercices = usager.exercicesResults
                    ? usager.exercicesResults
                    : [{ idAtelier: 1, idExercice: 0 }];


                var newAtelierId = exercices[exercices.length - 1].idAtelier;
                var newExerciceId =
                    exercices[exercices.length - 1].idExercice + 1;

                this.props.history.push(
                    `/Workshop/${newAtelierId}/Exercice/${newExerciceId}/${this.adminId}/${this.studentId}`
                );
            });
    }
    render() {
        return <div className="openingChrome"></div>;
    }
}

export default withRouter(Home);
