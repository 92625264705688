import React, {Component} from 'react'
import { connect } from 'react-redux'

import { changePathFileExplorer } from '../../actions/changePathFileExplorer'

import '../../../assets/css/app/core/AppContent.css'

import {  ExpandMore }  from '@material-ui/icons';


import desktopIcon from '../../../assets/img/fileExplorer/Desktop.png'
import documentsIcon from '../../../assets/img/fileExplorer/Documents.png'
import downloadIcon from '../../../assets/img/fileExplorer/Download.png'
import picturesIcon from '../../../assets/img/fileExplorer/Pictures.png'
import desktopFolderIcon from '../../../assets/img/fileExplorer/DesktopFolder.png'
import picturesFolderIcon from '../../../assets/img/fileExplorer/PicturesFolder.png'
import documentsFolderIcon from '../../../assets/img/fileExplorer/DocumentsFolder.png'
import musicsFolderIcon from '../../../assets/img/fileExplorer/MusicFolder.png'

class FileExplorerDocuments extends Component{
    
    handleChangePath = (newPath) => {
        console.log("Click on Handle change path")
        this.props.changePathFileExplorer(this.props.info.id, newPath)
    }
    render() {   
        if(this.props.info.folder =="Home"){
            return(
                <div>
                    <h5>Dossiers fréquents<ExpandMore fontSize="small"/></h5>
                    <div className="row"  style={{marginLeft:"20px"}} >    
                        <div className="col-md-3"><img alt="desktopFolderIcon" src={desktopFolderIcon} width="36px" height="36px"/>Bureau</div>
                        <div className="col-md-3" ><img alt="documentsFolderIcon" src={documentsFolderIcon} width="36px" height="36px" onClick={() => this.handleChangePath("Documents")}/>Documents</div>
                        <div className="col-md-3"><img alt="picturesFolderIcon" src={picturesFolderIcon} width="36px" height="36px"/>Images</div>
                        <div className="col-md-3"><img alt="musicsFolderIcon" src={musicsFolderIcon} width="36px" height="36px"/>Musiques</div>
                    </div>
                    <h5>Home<ExpandMore fontSize="small"/></h5>
                        <div className="row" style={{marginTop : "5%", marginLeft:"20px"}}>
                            <div className="col-md-6" >
                            </div>
                        </div>
                </div>
            )
        }
        else{
            return(
                <div>
                <table>
                    <thead>
                        <tr>
                            <th colspan="2">Nom</th>
                            <th>Modifié le</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><img alt="desktopIcon" src={desktopIcon} width="16px" height="16px" /></td>
                            <td>Bureau</td>
                            <td>03/01/2021 11:40</td>
                        </tr>
                        <tr>
                            <td><img alt="downloadIcon" src={downloadIcon} width="16px" height="16px"/></td>
                            <td>Téléchargements</td>
                            <td>03/01/2021 11:40</td>
                        </tr>
                        <tr>
                            <td><img alt="downloadIcon" src={downloadIcon} width="16px" height="16px"/></td>
                            <td>Téléchargements</td>
                            <td>03/01/2021 11:40</td>
                        </tr>
                        <tr>
                            <td><img alt="downloadIcon" src={downloadIcon} width="16px" height="16px"/></td>
                            <td>Téléchargements</td>
                            <td>03/01/2021 11:40</td>
                        </tr>
                        <tr>
                            <td><img alt="documentsIcon" src={documentsIcon} width="16px" height="16px"/>Documents</td>
                            <td><img alt="picturesIcon" src={picturesIcon} width="16px" height="16px"/>Images</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            )
        }
        
    
}}

const mapStateToProps = (state, ownProps) => {
    let id = ownProps.id;
    return {
        id : id,
        info: state.applications[id]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changePathFileExplorer: (id, newPath) => dispatch(changePathFileExplorer(id, newPath))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(FileExplorerDocuments)