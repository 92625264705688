import React, {Component} from 'react';


import './assets/css/Windows10.css';


import { connect } from 'react-redux'
import { hideApplication } from './components/actions/hideApplication'
import { openApplication } from './components/actions/openApplication'
import { putForwardApplication } from './components/actions/putForwardApplication'

import NotepadApp from "./components/app/NotepadApp";
import ChromeApp from "./components/app/ChromeApp";
import WindowsTaskbar from "./components/desktop/WindowsTaskbar";
import WindowsDesktop from "./components/desktop/WindowsDesktop";
import CasesApp from './components/app/CasesApp';
import FileExplorer from './components/app/FileExplorer';
import TextEditor from './components/app/TextEditor';

class Windows10 extends Component {
    componentDidMount(){
        //document.body.requestFullscreen();
    }

    handleClick = (e) => {
        if(e.target.className === "App desktop"){
            this.props.putForwardApplication(0);
        }
    }
    render() {
        return (
            <div className="App desktop" onClick={ this.handleClick } >
                <WindowsTaskbar id={0}/>
                <WindowsDesktop id={1}/>
                <NotepadApp id={5}/>
                <ChromeApp id={6}/>
                <CasesApp id={7}/>
                <FileExplorer id={8}/>
                <TextEditor id={9}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(Windows10);