import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import logo from '../../assets/img/logoToolPad.png'; // Tell webpack this JS file uses this image

import "../../assets/css/tutorials/OpeningChrome.css"
require('typeface-montserrat')

class Goodbye extends Component{

    render() {
        return(
            <div className="openingChrome">
                <header className="headerPage">
                    <Link to="/">
                        <h1 className="accueil">Accueil</h1>
                    </Link>
                    <a href="https://www.toolpad.fr/">
                        <img src={logo} alt="Logo" />
                    </a>
                </header>
                <div className="center">
                    <div className="card">
                        <h1 className="exercice">Merci d'avoir utilisé AtelierNum développé par ToolPad. A bientôt !</h1>
                    </div>
                    <a href="https://www.toolpad.fr/">
                        <button className="next">www.toolpad.fr</button>
                    </a>
                </div>
                <footer className="footer">
                    <a href="https://www.toolpad.fr/">
                        <h1 >Outil développé par ToolPad : www.toolpad.fr</h1>
                    </a>
                </footer>
            </div>

        )
    }
}

export default Goodbye