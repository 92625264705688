import React, {Component} from 'react'

import { connect } from 'react-redux'
import { connectedWifi } from '../../../actions/connectedWifi'


class WifiName extends Component {
    state={
        open : false,
        connected : false,
    }
    handleWifiClick = (e) => {
        console.log("Clique sur : ", e.target.id)
        if(this.state.open===false){
            console.log("Open")
            this.setState({open:true})
            
        }
        else if(e.target.id==="ConnecterWifi"){
            this.setState({connected:!this.state.connected})
            if(this.props.name==="Livebox-E5e12"){
                this.props.connectedWifi(this.props.id, true);
            }
        }
        else{
            console.log("Close")
            this.setState({open:false})
        }
    }

    render() { 
        if(this.state.open===false){
            return (
                <div className="row item" onClick={this.handleWifiClick}>
                    <div className="col-xs-2">
                        <i className= "fas fa-wifi" />
                    </div>
                    <div className="col-xs-10">
                        <span>{this.props.name}</span>
                        <br/>
                        <p>Sécurisé</p>
                    </div>
                </div>
              );
        }
        else{
            return (
                <div className="row item" onClick={this.handleWifiClick} style={{ backgroundColor:"#003a67"}}>
                    <div className="col-xs-2">
                        <i className= "fas fa-wifi" />
                    </div>
                    <div className="col-xs-10">
                        <span>{this.props.name}</span>
                        <br/>
                        <p>Sécurisé</p>
                        <p style={{ textDecoration:"underline"}}>Propriété</p>
                        <button id="ConnecterWifi">
                            {this.state.connected ? "Deconnecter" : "Connecter"}
                        </button>
                    </div>
                </div>
            );
        }
    }
}
 
const mapStateToProps = (state, ownProps) => {
    return {
        id : ownProps.id,
        applications: state.applications[ownProps.id]
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        connectedWifi: (id, wifi) => dispatch(connectedWifi(id, wifi))
    }
  }



export default connect(mapStateToProps, mapDispatchToProps)(WifiName)
