import React, {Component} from 'react'

import  WifiName  from "./Wifi/WifiName"

import { connect } from 'react-redux'

import '../../../assets/css/desktop/appBar/WifiMenu.css'


class Wifi extends Component{
    render() {        
        if(this.props.applications[this.props.id].isHide === false){
            return(
                <div className={`all-icons ${!this.props.applications[this.props.id].isHide ? "open" : ""}`}>
                    <div className="col-xs-12 programsWifiMenu" style={{ backgroundColor:"#292929"}}>
                        <WifiName name="Livebox-E5e12" idWifi={0} id={this.props.id}/>
                        <WifiName name="Freboox public" idWifi={1} id={this.props.id}/>
                        <WifiName name="SFR mobile" idWifi={2} id={this.props.id}/>
                    </div>
                </div>
            )
        }
            else{
                return(null)
            }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id : ownProps.id,
        applications: state.applications
    }
}

export default connect(mapStateToProps)(Wifi)