const initState = {
    applications : [
      {name:'TaskBar', zindex : 1, isHide : true, isClose : true, automaticallyHide : false, id : 0},
      {name:'WindowsDesktop', zindex : 1, isHide : false, isClose : false, automaticallyHide : false, id : 1},
      {name:'StartMenu', zindex : 1, isHide : true, isClose : true, automaticallyHide : true, id : 2, powerOff : false},
      {name:'TrayBar', zindex : 1, isHide : true, isClose : true, automaticallyHide : true, id : 3},      
      {name:'Wifi', zindex : 1, isHide : true, isClose : true, automaticallyHide : true, id : 4, connected : false},
      {name:'Notepad', zindex : 1, isHide : true, isClose : false, automaticallyHide : false, id : 5, className : ".notepad1-app", width: 600, height: 480,x: 75,y: 75, minHeight :300, minWidth :300, defaultWidth: 600, defaultHeight: 480, defaultX: 75, defaultY: 75},
      {name:'Chrome', zindex : 1, isHide : true, isClose : false, automaticallyHide : false, id : 6, className : ".chrome-app", width: 700, height: 580,x: 15,y: 15, minHeight :300, minWidth :300, defaultWidth: 700, defaultHeight: 580, defaultX: 15, defaultY: 15},
      {name:'4Cases', zindex : 1, isHide : true, isClose : false, automaticallyHide : true, caseOne : false, caseTwo : false, caseThree: false, caseFour : false, id : 7, type:0},
      {name:'FileExplorer', zindex : 1, isHide : true, isClose : false, automaticallyHide : false, id : 8, className : ".fileExplorer-app",folder:"Home", width: 600, height: 480,x: 75,y: 75, minHeight :300, minWidth :300, defaultWidth: 600, defaultHeight: 480, defaultX: 75, defaultY: 75},
      {name:'TextEditor', zindex : 1, isHide : false, isClose : false, automaticallyHide : false, id : 9, className : ".fileExplorer-app",folder:"Home", width: 600, height: 480,x: 75,y: 75, minHeight :300, minWidth :300, defaultWidth: 600, defaultHeight: 480, defaultX: 75, defaultY: 75},
    ]
  }
  
  const rootReducer = (state = initState, action) => {

    /*
    *  -- Put forward an Application --
    */
   var applications = JSON.parse(JSON.stringify(state.applications))

   console.log("Action", action)
   console.log("applications", applications)




    if(action.type === 'PUT_FORWARD_APP'){   
        
        
        console.log("PutForward")

        for(let element of applications){
            if(element.id !== action.id){
                if(element.zindex !== 0 && element.zindex !== 1){
                    element.zindex--;
                }
                if(element.automaticallyHide === true && element.id !== action.id && element.isHide===false){
                    element.isHide = true;
                }
            }
        };

        applications[action.id].zindex = 9998;

        console.log(applications)
        return {
        ...state,
        applications: applications
        }
    }

    /*
    *  -- Hide an Application --
    */
    else if(action.type === 'HIDE_APP'){    
        
        applications[action.id].isHide = true;

        return {
        ...state,
        applications: applications
        }
    }

    /*
    *  -- Open an Application --
    */    
    else if(action.type === 'OPEN_APP'){    
        console.log("Reducer : Open app")
        applications[action.id].isHide = false;
        applications[action.id].isClose = false;
        applications[action.id].zindex = 9999;


        //Same as putForward
        for(let element of applications){
            if(element.zindex !== 0 && element.zindex !== 1){
                element.zindex--;
            }
            if(element.automaticallyHide === true && element.id !== action.id){
                element.isHide = true;
            }
        };

        console.log("State")
        console.log(applications)
        return {
        ...state,
        applications: applications
        }
    }

    
    /*
    *  -- Resize an Application --
    */  
   else if(action.type === 'RESIZE_APP'){    
    console.log("Reducer : resize app")

    applications[action.id].width = action.width;
    applications[action.id].height = action.height;

    return {
        ...state,
        applications: applications
        }
    }


    /*
    *  -- Full resize an Application --
    */  

   else if(action.type === 'FULL_RESIZE_APP'){    
    console.log("Reducer : full resize")

    applications[action.id].x = 0;
    applications[action.id].y = 0;

    applications[action.id].width = "100%";
    applications[action.id].height = "100%";
    return {
        ...state,
        applications: applications
        }
    }
    
    /*
    *  -- Drag an Application --
    */  

   else if(action.type === 'DRAG_APP'){    
    console.log("Reducer : drag app")

    applications[action.id].x = action.x;
    applications[action.id].y = action.y;

    return {
        ...state,
        applications: applications
        }
    }

    /*
    *  -- Drag an Application --
    */  

   else if(action.type === 'DEFINE_APP'){    
    console.log("Reducer : define app")

    applications = action.applications;

    return {
        ...state,
        applications: applications
        }
    }

    /*
    *  -- Drag an Application --
    */  

   else if(action.type === 'CONNECTED_WIFI'){    
    console.log("Reducer : connected wifi")

    applications[action.id].connected = action.wifi;


    return {
        ...state,
        applications: applications
        }
    }

    /*
    *  -- Power off the computer --
    */  

   else if(action.type === 'POWER_OFF'){    
    console.log("Reducer : power off")

    applications[action.id].powerOff = true;


    return {
        ...state,
        applications: applications
        }
    }

    else if(action.type === 'VALIDATE_CASE'){    
        console.log("Reducer : VALIDATE_CASE")
        console.log(action.id)
        switch (action.id) {
            case 1: 
                applications[7].caseOne = true ;
                break;
            case 2:
                applications[7].caseTwo = true ;
                break;
            case 3:
                applications[7].caseThree = true ;
                break;
            case 4:
                applications[7].caseFour = true ;
                break;
            default:
                console.log("Erreur RootReducer VALIDATE_CASE")
        }
        return {
            ...state,
            applications: applications
            }
        }

        else if(action.type === 'CHANGE_PATH_FILE_EXPLORER'){    
            console.log("Reducer : CHANGE_PATH_FILE_EXPLORER")
            console.log(action.id)
            applications[8].folder = action.newPath
            return {
                ...state,
                applications: applications
                }
            }

    return state;
  }
  
  export default rootReducer