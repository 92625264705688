import React from 'react';
import ReactDOM from 'react-dom';

import './assets/css/index.css';
import 'normalize.css/normalize.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'

import {BrowserRouter, Route, Switch} from 'react-router-dom'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './components/reducers/rootReducer'

import Windows10 from './Windows10';


import Bienvenue from './components/tutorials/bienvenue/Bienvenue';

import Goodbye from './components/tutorials/Goodbye';

import Exercice from './components/tutorials/Exercice';

import Home from './components/tutorials/Home';

const DEBUG = true;

const store = createStore(rootReducer);

if(!DEBUG){
  console.log = function() {}
}

ReactDOM.render(
    <React.StrictMode>
      <Provider store = {store}>
        <BrowserRouter>
          <Switch>

            <Route exact path="/">
              <Bienvenue/>
            </Route>
            
            <Route exact path="/home/:adminId/:studentId">
              <Home/>
            </Route>

            <Route path="/Workshop/:workshopsId/Exercice/:exerciceId/:adminId/:studentId">
              <Exercice/>
            </Route>

            <Route path="/Workshop/:workshopsId/Exercice/:exerciceId/">
              <Exercice/>
            </Route>

            <Route path="/Goodbye">
              <Goodbye/>
            </Route>

            <Route path="/bacASable">
              <Windows10/>
            </Route>

          </Switch>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);
