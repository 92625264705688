import React, {Component} from 'react'

import { connect } from 'react-redux'

import profilAvatar from '../../assets/img/profil-avatar.jpg';
import StartMenuTile from "./appBar/StartMenuTile";
import StartMenuList from "./appBar/StartMenuList";
import PowerMenu from "./appBar/PowerMenu";

import '../../assets/css/desktop/WindowsStartMenu.css'

import notepadIcon from '../../assets/img/notepadIcon.png'
import chromeIcon from '../../assets/img/chromeIcon.png'


class WindowsStartMenu extends Component{
    state={
        powerHide : true,
    }


    handleClickPower = () => {
        if(this.state.powerHide === true){
            this.setState({powerHide: false});
        }
        else{
            this.setState({powerHide: true});
        }
    }

    render() {
        return(
            <div className={`startmenu ${!this.props.applications[this.props.id].isHide ? "open2" : ""}`}>
                <div className="container-fluid">
                                <PowerMenu isHide={this.state.powerHide} id={this.props.id}/>
                    <div className="row">
                        <div className="col icons">
                            <div className="bottom" style={{textAlignment: "centere"}}>
                                <div className="icon profile" title="Callum Barnes" style={{position: "relative"}}>
                                    <img
                                        alt="img-profil"
                                        className="img-responsive"
                                        src={profilAvatar}
                                    />
                                    <span>Callum Barnes</span>
                                </div>
                                <div className="icon file-explorer" title="File Explorer" style={{position: "relative"}}>
                                    <i className="fal fa-folder"/>
                                    <span>File Explorer</span>
                                </div>
                                <div className="icon settings" title="Settings" style={{position: "relative"}}>
                                    <i className="fal fa-cog"/>
                                    <span>Settings</span>
                                </div>                          
                                <div className="icon power" title="Power" style={{position: "relative"}} onClick={this.handleClickPower}>
                                    <i className="fal fa-power-off"/>
                                    <span>Power</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-4 col-xs-offset-2 programs">
                            <p className="title">C</p>
                            <StartMenuList icon="fas fa-camera" text="Camera"/>
                            <p className="title">E</p>
                            <StartMenuList icon="fas fa-file-excel" text="Excel"/>
                            <p className="title">G</p>
                            <StartMenuList imageApp={chromeIcon} icon="fab fa-chrome" text="Google Chrome" id={6}/>
                            <p className="title">M</p>
                            <StartMenuList icon="fab fa-firefox" text="Mozilla Firefox"/>
                            <p className="title">N</p>
                            <StartMenuList imageApp={notepadIcon} icon="fas fa-sticky-note" text="Notepad" id={5}/>
                            <p className="title">S</p>
                            <StartMenuList icon="fab fa-skype" text="Skype"/>
                            <p className="title">W</p>
                            <StartMenuList icon="fas fa-file-word" text="Word"/>
                        </div>
                        <div className="col-xs-6 tiles">
                            <div className="title">
                                <StartMenuTile size="wide" icon="fas fa-calendar" iconSize="iconSize-large" text="Calendrier"/>
                                <StartMenuTile size="medium" icon="fas fa-file-pdf" iconSize="iconSize-medium" text="Adobe Acrobat"/>
                                <StartMenuTile size="medium" icon="fas fa-image" iconSize="iconSize-medium" text="Photos"/>
                                <StartMenuTile size="medium" icon="fab fa-twitter" iconSize="iconSize-medium" text="Twitter"/>
                                <StartMenuTile size="medium" icon="fas fa-envelope" iconSize="iconSize-medium" text="Mail"/>
                                <StartMenuTile size="wide" icon="fas fa-newspaper" iconSize="iconSize-large" text="News"/>
                                <StartMenuTile size="medium" icon="fas fa-camera" iconSize="iconSize-medium" text="Calculatrice"/>
                                <StartMenuTile size="medium" icon="fas fa-calculator" iconSize="iconSize-medium" text="Camera"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id : ownProps.id,
        applications: state.applications
    }
}


export default connect(mapStateToProps)(WindowsStartMenu)

  