import React, {Component} from 'react'

import { connect } from 'react-redux'
import { hideApplication } from '../actions/hideApplication'
import { openApplication } from '../actions/openApplication'
import { putForwardApplication } from '../actions/putForwardApplication'

import '../../assets/css/desktop/WindowsDesktop.css'
import DesktopIcon from "./appDesktop/DesktopIcon";


import notepadIcon from '../../assets/img/notepadIcon.png'
import chromeIcon from '../../assets/img/chromeIcon.png'
import fileExplorerIcon from '../../assets/img/FileExplorer.png'
import textEditorIcon from '../../assets/img/textEditor.png'

class WindowsDesktop extends Component{



    render() {
        return(
            <div className="desktop2">
                <div className="programs">
                    <DesktopIcon softwareId="Bloc-notes" imageApp={notepadIcon} id={5}/>
                    <DesktopIcon softwareId="Google Chrome" imageApp={chromeIcon} id={6}/>
                    <DesktopIcon softwareId="Explorateur de fichiers" imageApp={fileExplorerIcon} id={8}/>
                    <DesktopIcon softwareId="Editeur de texte" imageApp={textEditorIcon} id={9}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id : ownProps.id,
        applications: state.applications
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        hideApplication: (id) => dispatch(hideApplication(id)),
        openApplication: (id) => dispatch(openApplication(id)),
        putForwardApplication: (id) => dispatch(putForwardApplication(id))
    }
  }



export default connect(mapStateToProps, mapDispatchToProps)(WindowsDesktop)